import React from 'react';

export const RenderCvSvg = (style) => {
    style = style == null ? {} : style;
    return(
        <svg style={style} 
            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32" space="preserve">
            <path d='M29,0H3C1.3,0,0,1.3,0,3v26c0,1.7,1.3,3,3,3h26c1.7,0,3-1.3,3-3V3C32,1.3,30.7,0,29,0z M6,2h13v8l3-2l3,2V2 h3c1.1,0,2,0.9,2,2v15H6V2z M2,4c0-1.1,0.9-2,2-2v17c-0.7,0-1.4,0.1-2,0.3V4z M30,23v5c0,1-1,2-2,2c-6.1,0-24,0-24,0 c-1.1,0-2-0.9-2-2v-5c0-1.1,0.9-2,2-2h26C30,21,30,21.9,30,23z M27,26H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h22c0.6,0,1-0.4,1-1 S27.6,26,27,26z M27,23H5c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1C28,23.4,27.6,23,27,23z' />
        </svg>
    )
}

export const RenderTechnicalSkillsSvg = (style) => {
    style = style == null ? {} : style;
    return(
        <svg style={style} 
            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32" space="preserve">
            <g>
            <path fillRule="evenodd" clipRule="evenodd" d="M32,3c0-1.7-1.3-3-3-3H3C1.3,0,0,1.3,0,3v20c0,1.7,1.3,3,3,3h26 c1.7,0,3-1.3,3-3V3z M30,22c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h24c1.1,0,2,0.9,2,2V22z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2,19h28v2H2V19z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10,30h12c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1H10c-0.6,0-1-0.4-1-1 C9,30.4,9.4,30,10,30z" />
            <g>
            <rect x="11" y="26" fillRule="evenodd" clipRule="evenodd" width="2" height="4" />
            <rect x="19" y="26" fillRule="evenodd" clipRule="evenodd" width="2" height="4" />
            </g>
            </g>
        </svg>
    )
}

export const RenderContactSvg = (style) => {
    style = style == null ? {} : style;
    return(
        <svg style={style} 
            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32" space="preserve">
            <path d="M19,9h-8v1h8V9z M22,11H11v1h11V11z M31,11.4C31,11.4,31,11.4,31,11.4l-4-3.4V4h-5l-3.9-3.3 c-1.2-0.9-2.8-0.9-4.1,0L10.1,4H6v3.4l-4.9,4.1C0.4,11.8,0,12.6,0,14v15c0,1.7,1.3,3,3,3h26c1.7,0,3-1.3,3-3V14 C32,12.6,31.6,11.9,31,11.4z M27,10.7l1.9,1.6L28.2,13H28c0.1,0,0.1,0,0.2,0L27,14V10.7z M15.3,2.2c0.2-0.2,0.5-0.3,0.8-0.3 c0.3,0,0.6,0.1,0.8,0.2L19,4h-5.8L15.3,2.2z M8,6h17v9.8l-8.2,7.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0-0.5-0.1-0.8-0.3L8,16.1V6z M6,9.9v4.4L3.5,12L6,9.9z M2.1,28.6C2,28.4,2,28.2,2,28V15c0-0.5,0.2-0.9,0.4-1.3l8.1,7.4L2.1,28.6z M28,30H4c-0.1,0-0.3,0-0.4,0 l8.4-7.5l0,0l2,1.9c1.2,1,2.9,0.9,4.1,0l2-1.8l8.3,7.4C28.3,30,28.1,30,28,30z M30,28c0,0.2,0,0.4-0.1,0.6l-8.2-7.3l8.2-7.1 c0.1,0.3,0.2,0.5,0.2,0.8V28z M21,13H11v1h10V13z" />
        </svg>
    )
}


export const RenderAboutMeSvg = (style) => {
    style = style == null ? {} : style;
    return(
        <svg style={style} 
            version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="45.308px" height="45.308px" viewBox="0 0 45.308 45.308"
                space="preserve">
        <g>
       <path d="M25.155,25.209c0,0.553-1.119,1-2.5,1s-2.5-0.447-2.5-1s1.119-1,2.5-1S25.155,24.656,25.155,25.209z M39.964,28.768
           c0.006,0.105,0.024,0.196,0.024,0.315v7.725c0,4.687-4.353,8.5-9.704,8.5h-15.26c-5.351,0-9.704-3.813-9.704-8.5v-7.724
           c0-0.115,0.008-0.221,0.016-0.324c-2.429-0.898-4.183-3.32-4.183-6.176c0-2.135,0.974-4.033,2.478-5.231
           c0.227-2.12,0.7-4.632,1.506-6.402C5.335,10.504,10.107,0,22.654,0c12.425,0,17.354,10.453,17.561,10.906
           c0.826,1.816,1.306,4.367,1.529,6.501c1.467,1.202,2.41,3.076,2.41,5.177C44.155,25.445,42.398,27.871,39.964,28.768z
            M25.317,34.311l-0.474-2.123h-4.347L20.01,34.3c0.666-0.299,1.602-0.487,2.645-0.487C23.708,33.812,24.649,34.006,25.317,34.311z
            M40.155,22.584c0-1.412-0.996-2.559-2.237-2.562c-0.343,0.586-0.734,1.149-1.181,1.687c0.416-1.428,0.644-2.896,0.644-4.354
           c0-2.901-0.893-5.35-2.414-7.294c-0.049-0.063-0.09-0.122-0.144-0.188c-2.651-3.243-7.11-5.02-12.168-5.02
           c-4.876,0-9.187,1.659-11.866,4.686c-0.496,0.595-0.9,1.154-1.203,1.625c-1.052,1.743-1.656,3.813-1.656,6.189
           c0,1.457,0.228,2.926,0.644,4.354c-0.445-0.537-0.838-1.102-1.18-1.688c-1.242,0.003-2.238,1.148-2.238,2.563
           s0.998,2.563,2.242,2.563c0.311,0,0.605-0.072,0.874-0.201c0.289,0.844,0.66,1.647,1.089,2.418
           c1.913-0.103,4.363,0.805,6.535,2.596c0.313,0.258,0.583,0.514,0.834,0.768l0.228-0.986c0.209-0.907,1.017-1.551,1.949-1.551h7.541
           c0.937,0,1.749,0.648,1.952,1.564l0.21,0.941c0.244-0.244,0.505-0.488,0.806-0.736c2.194-1.811,4.674-2.715,6.596-2.592
           c0.428-0.764,0.795-1.564,1.084-2.402c0.254,0.115,0.529,0.184,0.818,0.184C39.157,25.146,40.155,24,40.155,22.584z M15.863,16.517
           c-1.241,0-2.25,1.01-2.25,2.25c0,1.24,1.009,2.25,2.25,2.25s2.25-1.01,2.25-2.25C18.113,17.527,17.104,16.517,15.863,16.517z
            M29.447,16.517c-1.241,0-2.25,1.01-2.25,2.25c0,1.24,1.009,2.25,2.25,2.25s2.25-1.01,2.25-2.25
           C31.697,17.527,30.688,16.517,29.447,16.517z"/>
        </g>
   </svg>
    )
}

export const RenderHomeSvg = (style) => {
    style = style == null ? {} : style;
    return(
        <svg style={style}
            version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="460.298px" height="460.297px" viewBox="0 0 460.298 460.297"
            space="preserve">
            <g>
                <g>
                    <path d="M230.149,120.939L65.986,256.274c0,0.191-0.048,0.472-0.144,0.855c-0.094,0.38-0.144,0.656-0.144,0.852v137.041
                        c0,4.948,1.809,9.236,5.426,12.847c3.616,3.613,7.898,5.431,12.847,5.431h109.63V303.664h73.097v109.64h109.629
                        c4.948,0,9.236-1.814,12.847-5.435c3.617-3.607,5.432-7.898,5.432-12.847V257.981c0-0.76-0.104-1.334-0.288-1.707L230.149,120.939
                        z"/>
                    <path d="M457.122,225.438L394.6,173.476V56.989c0-2.663-0.856-4.853-2.574-6.567c-1.704-1.712-3.894-2.568-6.563-2.568h-54.816
                        c-2.666,0-4.855,0.856-6.57,2.568c-1.711,1.714-2.566,3.905-2.566,6.567v55.673l-69.662-58.245
                        c-6.084-4.949-13.318-7.423-21.694-7.423c-8.375,0-15.608,2.474-21.698,7.423L3.172,225.438c-1.903,1.52-2.946,3.566-3.14,6.136
                        c-0.193,2.568,0.472,4.811,1.997,6.713l17.701,21.128c1.525,1.712,3.521,2.759,5.996,3.142c2.285,0.192,4.57-0.476,6.855-1.998
                        L230.149,95.817l197.57,164.741c1.526,1.328,3.521,1.991,5.996,1.991h0.858c2.471-0.376,4.463-1.43,5.996-3.138l17.703-21.125
                        c1.522-1.906,2.189-4.145,1.991-6.716C460.068,229.007,459.021,226.961,457.122,225.438z"/>
                </g>
            </g>
        </svg>
    )
}