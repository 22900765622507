import { RenderCvSvg, RenderTechnicalSkillsSvg, RenderContactSvg, RenderAboutMeSvg, RenderHomeSvg } from './svgImages';

export const links =  [
    {
        link: '/cv',
        name: 'Curriculum',
        func: RenderCvSvg
    },
    {
        link: '/technicalSkills',
        name: 'Skills',
        func: RenderTechnicalSkillsSvg
    },
    {
        link: '/about',
        name: 'About Me',
        func: RenderAboutMeSvg
    },
    {
        link: '/contact',
        name: 'Contact',
        func: RenderContactSvg
    }
];


export const allLinks = [
    { key: -1, link: '/', func: RenderHomeSvg, name: 'Home' },
    ...links,    
];