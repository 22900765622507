import React from 'react';
import {
    Image,
    TransitionablePortal,
    Header,
    Button,
    Icon,
    List,
    Segment,
    Container
} from 'semantic-ui-react'

import { imageList, getImageUrl } from './imageFunctions';

const imageWidth = 200;
const imageHeight = 300;
const BaseRowHeight = 300;

const findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

const getCurrentInxex = (image) => {
    return findWithAttr(imageList, 'fileName', image.fileName);
}

class imageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            width: 0,
            height: 0,
            rows: 0,
            perRow: 0,
            currentPageIndex: 0,
            totalImages: 0,
        };
    }

    initialState = {
        rows: 0,
        perRow: 0,
        totalImages: 0,
        imagesPerPage: 0,
        currentPageIndex: 0,
        showAll: false,
        image: null,
        toggleClassName: false,
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.selectedImage) {
            return { image: null }
        }

        const perRow = Math.floor(state.width / imageWidth);
        const availableHeight = state.height - BaseRowHeight;
        const rows = Math.floor(availableHeight / imageHeight);
        const minRows = rows === 0 ? 1 : rows;
        const imagesPerPage = minRows * perRow;

        return {
            ...state,
            image: !state.image ? props.selectedImage : state.image,
            rows: minRows,
            perRow: perRow,
            totalImages: imageList.length,
            imagesPerPage: imagesPerPage,
        };
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    moveBackward = (currentImage, toggleClassName) => {
        const currentIndex = getCurrentInxex(currentImage);
        const nextNumber = currentIndex === 0 ? imageList.length - 1 : currentIndex - 1;
        const nextImage = imageList[nextNumber];

        this.setState({ image: nextImage, toggleClassName: !toggleClassName });
    }

    moveForward = (currentImage, toggleClassName) => {
        const currentIndex = getCurrentInxex(currentImage);
        const nextNumber = (imageList.length - 1) <= currentIndex ? 0 : currentIndex + 1;
        const nextImage = imageList[nextNumber];

        this.setState({ image: nextImage, toggleClassName: !toggleClassName });
    }

    toggleShowAll = () => {
        this.setState({ showAll: !this.state.showAll });
    }

    selectImage = (image) => {
        this.setState({ showAll: false, image: image });
    }

    closingModal = () => {
        this.setState(this.initialState);
    }

    onCloseLocal = () => {
        const { onClose } = this.props;
        this.closingModal();
        onClose();
    }

    onPaginationPrevious = () => {
        const { imagesPerPage, currentPageIndex } = this.state;
        const newIndex = currentPageIndex - imagesPerPage;
        
        this.setState({ currentPageIndex: newIndex < 0 ? 0 : newIndex });
    }

    onPaginationNext = () => {

        const { imagesPerPage, currentPageIndex, totalImages } = this.state;
        const nextPossibleIndex = currentPageIndex + imagesPerPage;

        this.setState({ currentPageIndex: nextPossibleIndex > totalImages ? totalImages - currentPageIndex : nextPossibleIndex });
    }

    render() {
        const { open } = this.props;
        const { image, showAll } = this.state;

        if (!open) {
            return (<div style={{ display: 'none' }}></div>)
        }

        const url = getImageUrl(image);

        const baseicImageProps = {
            src: url,
            id: image.fileName,
            bordered: true,
            alt: image.title,
            centered: true,
        }

        const imageProps = {
            ...baseicImageProps,
            style: { maxWidth: '960px', height: 'auto', maxHeight: '600px', width: 'auto' }
        }

        const renderShowAll = () => {

            const { currentPageIndex, imagesPerPage, totalImages, toggleClassName } = this.state;
            const numberOfImagesToFetch = currentPageIndex + imagesPerPage > totalImages ? totalImages : currentPageIndex + imagesPerPage;

            const imagesToShow = imageList.slice(currentPageIndex, numberOfImagesToFetch);
            const previousEnabled = currentPageIndex > 0;
            const nextEnabled = currentPageIndex < (totalImages - imagesPerPage);

            return (
                <div>
                    <Header icon='camera' content='Image Gallery' />
                    <Segment basic>
                        <List horizontal className='showAllGallery'>
                            {imagesToShow.map((item, i) => {
                                return (
                                    <List.Item key={item.fileName}>
                                        <Image  className={ toggleClassName ? 'toggle1 image' : 'toggle2 image'} size='small' src={getImageUrl(item)} onClick={() => this.selectImage(item)} />
                                    </List.Item>
                                )
                            })}
                        </List>
                    </Segment>

                    <Container textAlign='center'>
                        <Button.Group size='small' widths='5' style={{ minWidth: 400, maxWidth: '75%', marginTop: 20 }}>
                            <Button onClick={this.onCloseLocal} inverted className='navButton'>
                                <Icon name='window close' /> Close
                            </Button>
                            <Button onClick={this.onPaginationPrevious} inverted className='navButton' disabled={!previousEnabled}>
                                <Icon name='angle left' />
                            </Button>
                            <Button onClick={this.onPaginationNext} inverted className='navButton' disabled={!nextEnabled}>
                                <Icon name='angle right' />
                            </Button>
                            <Button onClick={this.toggleShowAll} inverted className='navButton'>
                                Back to Image
                            </Button>
                        </Button.Group>
                    </Container>
                </div>
            )
        }

        const renderSingleImage = () => {

            const { height, width, toggleClassName } = this.state;
            const maxHeight = height * 0.6;
            const maxWidth = width * 0.75;

            return (
                <div className='imageGallerySingle'>
                    <Header icon='camera' content={image.title} />
                    <Image {...imageProps} style={{ maxWidth: maxWidth, maxHeight: maxHeight }} className={ toggleClassName ? 'toggle1' : 'toggle2'} />

                    <Container textAlign='center'>
                        <Button.Group size='small' widths='5' style={{ minWidth: 400, maxWidth: '75%', marginTop: 20 }}>
                            <Button onClick={this.toggleShowAll} inverted className='navButton'>
                                <Icon name='expand arrows alternate' /> View all
                            </Button>
                            <Button onClick={() => this.moveBackward(image, toggleClassName)} inverted className='navButton'>
                                <Icon name='backward' />
                            </Button>
                            <Button onClick={() => this.moveForward(image, toggleClassName)} inverted className='navButton'>
                                <Icon name='forward' />
                            </Button>
                            <Button onClick={this.onCloseLocal} inverted className='navButton'>
                                <Icon name='window close' /> Close
                            </Button>
                        </Button.Group>
                    </Container>
                </div>)
        }
        return (
            <TransitionablePortal
                open={open}
                onClose={this.onCloseLocal}
                onHide={this.onCloseLocal}
                closeIcon
                basic
                dimmer='blurring'
                size='fullscreen'
                className='imageGalleryModal'
            >
                <Segment style={{ left: '5%', position: 'fixed', top: '5%', zIndex: 1000, width: '90%', height: '90%' }}>
                    {showAll && renderShowAll()}
                    {!showAll && renderSingleImage()}
                </Segment>

            </TransitionablePortal>
        )
    }

}

export default imageModal;