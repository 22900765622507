export const imageList = [
    {
        title: 'Charlotte and Jon',
        fileName: 'couple.png',
        index: 0,
    },
    {
        title: 'Dale Willaims',
        fileName: 'dale.png',
        index: 1,
    },
    {
        title: 'Ethan Wiliams',
        fileName: 'ethan.png',
        index: 2,
    },
    {
        title: 'Jon and Kendra',
        fileName: 'jon_kendra_tree.png',
        index: 3,
    },
    {
        title: 'Younger version of Jon',
        fileName: 'jon_train.png',
        index: 4,
    },
    {
        title: 'Young Kendra',
        fileName: 'kendra_run.png',
        index: 5,
    },
    {
        title: 'making Bread and Brioche',
        fileName: 'bread_brioche.jpg',
        index: 6,
    },
    {
        title: 'First home made pizza',
        fileName: 'first_pizza.jpg',
        index: 7,
    },
    {
        title: 'Dale, Ethan and Jon at the ball',
        fileName: 'gents_ball.jpg',
        index: 8,
    },
    {
        title: 'Raven before cutting her hair',
        fileName: 'hairy_raven.jpg',
        index: 9,
    },
    {
        title: 'showing off the beard',
        fileName: 'jon_beard.jpg',
        index: 10,
    },
    {
        title: 'My favourite hardtail',
        fileName: 'jon_bird.jpg',
        index: 11,
    },
    {
        title: 'Charlotte and Jon at the ball',
        fileName: 'jon_charlotte_ball.jpg',
        index: 12,
    },
    {
        title: 'Charlotte and Jon with a big moon',
        fileName: 'jon_charlotte_moon.jpg',
        index: 13,
    },
    {
        title: 'Jons Christmas jumper',
        fileName: 'jon_christmas.jpg',
        index: 14,
    },
    {
        title: 'Jon and Kendra at the ball',
        fileName: 'jon_kendra_ball.jpg',
        index: 15,
    },
    {
        title: 'Jon, Kendra and Raven taking a windy walk',
        fileName: 'jon_kendra_raven_windy_walk.jpg',
        index: 16,
    },
    {
        title: 'Jon and a muddy whyte',
        fileName: 'muddy_me_whyte.jpg',
        index: 17,
    },
    {
        title: 'a very muddy whyte',
        fileName: 'muddy_whyte.jpg',
        index: 18,
    },
    {
        title: 'Beautiful Raven',
        fileName: 'raven_black_and_white.jpg',
        index: 19,
    },
    {
        title: 'Raven chilling on the sofa',
        fileName: 'raven_sofa.jpg',
        index: 20,
    },
    {
        title: 'Whyte',
        fileName: 'whyte.jpg',
        index: 21,
    }
]

export const getImageUrl = (image) => {
    return `/images/gallery/${image.fileName}`;
}