import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Table, Responsive, Grid } from 'semantic-ui-react'

import RegularPageHolder from '../regularPageHolder';
import SubHeader from '../headers/subHeader';

import Skill from './skill';

const skills = [
    {
        percentage: 90,
        level: 'Expert',
        years: 13,
        title: 'C#',
        description: 'Has been developing in C# for 13+ years. Including MVC, console and Windows Service applications. Primarily in .NET 4.5 and Core 3.1',
    }, {
        percentage: 60,
        level: 'Proficient',
        years: 3,
        title: 'ReactJS',
        description: 'Been involved in multiple SPA applications, both with and without TypeScript',
    }, {
        percentage: 75,
        level: 'Advanced',
        years: 8,
        title: 'Web API',
        description: 'Almost every web project worked on have either consumed or created JSON APIs, as well as using JWT and OAuth processes',
    }, {
        percentage: 70,
        level: 'Advanced',
        years: 7,
        title: 'ASPNET MVC',
        description: 'over the 13 years of development many applications have based on MVC',
    }, {
        percentage: 60,
        level: 'Proficient',
        years: 10,
        title: 'SQL Server',
        description: 'SQL Server has been the primary database used over the years',
    }, {
        percentage: 75,
        level: 'Advanced',
        years: 5,
        title: 'Entity Framework',
        description: 'EF6 and Core, primarily code first, used across multiple projects',
    }, {
        percentage: 85,
        level: 'Advanced',
        years: 13,
        title: 'Full Stack Development',
        description: 'Has always been a full stack developer.  Using N-Tier architecture as well as SPA using JSON API calls',
    }, {
        percentage: 55,
        level: 'Proficient',
        years: 6,
        title: 'JQuery',
        description: 'some MVC projects have been heavily based around JQuery',
    }, {
        percentage: 69,
        level: 'Proficient',
        years: 6,
        title: 'HTML & CSS',
        description: 'Always aiming to produce responsive, clean and well formed websites',
    }, {
        percentage: 80,
        level: 'Advanced',
        years: 5,
        title: 'Agile Development',
        description: 'Been in multiple agile teams across two companies, experiencing how to fit the process to the company, rather than the company to the process',
    }, {
        percentage: 50,
        level: 'Intermediate',
        years: 2,
        title: 'CQRS',
        description: 'Has used a form of CQRS across multiple smaller projects',
    }, {
        percentage: 40,
        level: 'Beginner',
        years: 1,
        title: 'TypeScript',
        description: 'A Recent React application has been developed using Typescript',
    },
]

const technicalSkills = () => {
    return (
        <Container>

            <Helmet>
                <title>Technical Skills - Houseofhawkins.com</title>
                <meta name='description' content='programming skills' />
                <meta name="keywords" content="c#,csharp,dotnet,javascript,reactjs,react,entityframework,core" />
            </Helmet>

            <SubHeader title='Technical Skills' />

            <Responsive as={Container} minWidth={1024} textAlign='center'>
                <Grid container columns={3} centered>
                    {skills.map((skill, i) => {
                        return (
                            <Grid.Column key={skill.title} style={{ width: 250, margin: 30 }} className='skillsBox'>
                                <Skill skill={skill} minHeight={400} />
                            </Grid.Column>
                        )
                    }
                    )}
                </Grid>
            </Responsive>

            <Responsive as={Table} maxWidth={1023} celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Years</Table.HeaderCell>
                        <Table.HeaderCell>level</Table.HeaderCell>
                        <Responsive as={Table.HeaderCell} minWidth={500}>description</Responsive>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {skills.map((skill, i) => {
                        return (
                            <Table.Row key={skill.title}>
                                <Table.Cell>{skill.title}</Table.Cell>
                                <Table.Cell>{skill.years}</Table.Cell>
                                <Table.Cell>{skill.level}</Table.Cell>
                                <Responsive as={Table.Cell} minWidth={500}>{skill.description}</Responsive>
                            </Table.Row>
                        )
                    })}
                </Table.Body>

            </Responsive>


        </Container>
    )
}

export default RegularPageHolder(technicalSkills);