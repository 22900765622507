import React from 'react';
import { Visibility, Loader } from 'semantic-ui-react'

function lazyComponent() {
    return (WrappedComponent) => {
        class LazyImage extends React.Component {
            state = {
                show: false,
            }

            showImage = () => {
                this.setState({
                    show: true,
                })
            }           

            render() {
                const { minHeight } = this.props
                const minH = !minHeight ? 300 : minHeight;
                
                if (!this.state.show && !this.state.onScreen) {
                    return (
                        <Visibility as="span" fireOnMount onTopVisible={this.showImage}>
                            <Loader active inline="centered" style={{ minHeight: minH }} />
                        </Visibility>
                    )
                }
                return <WrappedComponent {...this.props} />
            }
        }

        return LazyImage;
    }
}

export default lazyComponent;
