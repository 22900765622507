import React from 'react';
import { Responsive, Container } from 'semantic-ui-react'

class BackgroundMe extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false};
      }

    static getDerivedStateFromProps(props, state) {
        return { showMe: props.location.pathname === '/' };
    }

componentDidMount(){
    this.timerID = setInterval(
        () => this.tick(),
        500
      );
}

tick() {
    this.setState({loaded: true});
    clearInterval(this.timerID);
  }

    render() {
        if(!this.state.showMe) {
            return(<div></div>);
        }

        return(
            <Responsive as={Container} minWidth={1024} className={ this.state.loaded ? 'backgroundReady homepage-bg' : 'homepage-bg'}>

            </Responsive>
        )
    }
}

export default BackgroundMe;