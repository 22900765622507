import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Grid, Responsive } from 'semantic-ui-react'

import RegularPageHolder from '../regularPageHolder';
import SubHeader from '../headers/subHeader';
import Timeline from './timeline';

const jobStyle = {
    display: 'table-cell',
    verticalAlign: 'top',
    padding: '45px 0 37px 66px',

    borderLeft: '2px solid rgb(238, 236, 234)',
}

const jobStyleTiny = {
    padding: '25px 0 37px 0',
}


const dwellant = (style) => {
    return(
        <Container className='jobInfo' style={style}>
            <p>
                <b>Dwellant</b>, an award-winning property tech company, provide a holistic approach for property managers; from managing a whole building and its tenants, to contractors, invoices and concierge package management.  
            </p>

            <p>
                He was brought on board to help modernize and extend the application(s).  His roles include:
            </p>

            <ol>
                <li>Helping drive the move to ReactJS and a Restful API design</li>
                <li>Assisting the two frontend developers with React and interfacing with the .net backend environment.</li>
                <li>Hep further automate the testing and deployment of all the environments.</li>
                <li>Support and extend the existing applications.</li>
            </ol>
        </Container>
    )
}

const redRiverTeamLead = (style) => {
    return(
        <Container className='jobInfo' style={style}>
            <p>
                <b>Red River Software</b> is predominantly a software house delivering bespoke software to a variety of industries. This role covered the full development cycle from initial client discussions, through to design and architecture, and finally implementation, testing and deployment. Fast became an advocate of Agile development methodologies, and a key member of the SCRUM team. 
            </p>
            <p>
                The role of Development Team Lead is to assist senior management and remove some of the pressure from their shoulders.  Responsibilities included
            </p>
            <ol>
                <li>Guide the development team, both technically and ensuring issues are resolved quickly</li>
                <li>Part of the steering group for Red River.  To help guide the progression of our staff and company</li>
                <li>Part of the tech steering group.  To help solidify our practices and see if new technologies or processes / patterns could improve our development and products</li>
                <li>Along with the scrum master ensure that our development process is efficient and working with us, rather than causing any barriers</li>
            </ol>
        </Container>
    )
}

const redRiverArchitect = (style) => {
    return(
        <Container className='jobInfo' style={style}>
            <p>The goal of the role was to try and improve our development process.  To look at frameworks, technologies, patterns and ideas to help improve our processes and products.  Achieved the following</p>
            <ol>
                <li>Introduced a more formal N-tier architecture for our new applications. This separated out our repository and business logic giving classes a single responsibility and decreasing their complexity. </li>
                <li>Introduced DI and IoC within some of our existing and all new applications.</li>
                <li>Ran workshops for all staff explaining patterns and practices, such as DI, IoC, N-tier, unit testing. </li>
                <li>Introduced unit testing with NUnit. </li>
                <li>Introduced unit testing with NUnit. e. Introduced CI Server and automated deployments and automated a number of ongoing projects that were being deployed on a regular basis.</li>
                <li>Architected a green field application that was designed to replace a slow and unresponsive desktop application.  Web API, EF6 and AngularJS were used to create a fast and user friendly application used across 9 countires. </li>
                <li>
                    Developed an application to assist our scrum and development process.
                    <ol>
                        <li>It would track user stories across source control branches to allow the Product Owners know where an item is within the development process.</li>
                        <li>Automated the created of user story tasks in TFS, (saved a lot of manual labour for developers every sprint). </li>
                        <li>Allowed the testing team to track change sets that needed to be moved to test.</li>
                    </ol>
                </li>
                <li>Analysing a legacy application and code base for a prospective customer to understand its structure and approximate complexity.  This facilitated Red River to successfully secure the largest contract to date. </li>
                <li>Created a framework to bulk insert hundreds of thousands of rows of complex test data into our applications quickly and cleanly to ensure that during development the systems where tested with a realistic volume of data to ensure queries responded within given tollerences.</li>                        
            </ol>
        </Container>
    )
}


const redRiverSeniorDeveloper = (style) => {
    return(
        <Container className='jobInfo' style={style}>
            <ol>
                <li>Produced an application to streamline and automate the package creation of a large and distributed application.  The application consisted of a signed WPF application, WCF Services (behind proxy servers) and multiple windows Services.  The application saved the development team a significant amount of time for every deployment. </li>
                <li>Developer within a distributed Agile SCRUM team.  Extending and supporting a large Client Server WPF document management application.</li>
                <li>Worked across multiple client projects and providing support to other developers where required. </li>
            </ol>
        </Container>
    )
}

const commercePartnership = (style) => {
    return(
        <Container className='jobInfo' style={style}>
            <p>
                <b>The Commerce Partnership Ltd</b> provide e-commerce development services to some of the UK’s most creative brands, including River Island and French Connection. 
            </p>
            <ol>
                <li>Developed a product feed that can have custom implementations, such as a Google product feed. </li>
                <li>Converted a client’s website to become multilingual.</li>
                <li>Implemented an automated email product review service. </li>
                <li>He was often involved in improving existing functionality or fixing issues that had been flagged up. </li>
                <li>Provided full SDLC across all clients.  He had to be flexible and adaptive at times to prioritize regular development with support issues and queries that arose. </li>
                <li>Deployed Hyper-V Server to replace various Old servers in Office.  Deployed a Continuous Integration Server into the deployment environment and significantly reduce the manual overhead of deployments from development to staging and then to the live servers. </li>
            </ol>
        </Container>
    )
}

const accenture = (style) => {
    return(
        <Container className='jobInfo' style={style}>
            <p>Transferred across from ACE to Accenture but continued to do the same role as an outsourced contractor.</p>
        </Container>
    )
}

const ace = (style) => {
    return(
        <Container className='jobInfo' style={style}>
            <p>
                <b>ACE European Group Ltd</b>;  An international insurance / reinsurance company operating in more than 50 countries.  Main projects and responsibilities included
            </p>
            <ol>
                <li>Re-development of an application to C#.  Converted a REXX script program into a C# console application to deal with loading policy data automation.  The application billed £350 million annually and required an optimized and automated solution that was customised for each of the 19 countries it was deployed to. </li>
                <li>Development of an application to consolidate and log in detail all versions of a reporting tool used in 19 countries on 7 servers across Europe.  This was key to assisting with the knowledge transfer process as there are 600+ reports in production, the application allowed the business to decide what needed to be focused on.</li>
                <li>Extensive testing of a website which was designed to be the tool to control and automate the batch processing applications.  The goal was to ensure the system worked correctly, dealt with errors safely and was secure. </li>
                <li>Management of the “Change Control” process. </li>
                <li>Provision of on-going support to the business teams across Europe.  Incidents would be investigated and a solution tested with the user and finally implemented through the change control process. </li>
            </ol>
        </Container>
    )
}


const sussexInfo = (style) => {
    return(
        <Container className='jobInfo' style={style}>
            <p>B.Sc (Hons) Degree in Computer Science and Artificial Intelligence. </p>
        </Container>
    )
}

const cv = () => {

    return(    
        <Container className='cv'>
            
            <Helmet>
                <title>CV - Work History - Houseofhawkins.com</title>
                <meta name='description' content='CV Work history' />
                <meta name='keywords' content='CV Work history c# csharp donet core reactjs ef entityframework' />
            </Helmet>

            <SubHeader title='Curriculum' />
            <p style={{ marginBottom: 25 }}>
                Characterised by a strong desire to design and produce clean, efficient, effective and elegant software solutions using the correct tools for the job at hand. An experienced developer with over eight years software development using .NET, and web technologies; with experience in architecting new applications, creating and adding new features to existing applications, plus supporting existing and legacy applications. Able to work independently as well as within a team environment. Always keen to learn and improve upon his development skills and knowledge both within and outside of work.
            </p>

            <Responsive as={Container} maxWidth={999} className='tinyFluidContainer'>
                <Timeline dateStr='Oct 2017 - Present' title='Senior Software Developer' company='Dwellant' />
                { dwellant(jobStyleTiny) }

                <Timeline dateStr='April 2015 - Oct 2017' title='Development Team Lead' company='Red River Software' />
                { redRiverTeamLead(jobStyleTiny) }
            
                <Timeline dateStr='Feb 2013 - April 2015' title='Senior Software Architect' company='Red River Software' />
                { redRiverArchitect(jobStyleTiny) }

                <Timeline dateStr='Feb 2011 - Feb 2013' title='Senior Developer' company='Red River Software' />
                { redRiverSeniorDeveloper(jobStyleTiny) }

                <Timeline dateStr='Nov 2009 - Feb 2011' title='Software Developer' company='Commerce Partnership' />
                { commercePartnership(jobStyleTiny) }

                <Timeline dateStr='2009 - Nov 2009' title='Analyst Developer' company='Accenture' />
                { accenture(jobStyleTiny) }

                <Timeline dateStr='2006 - 2009' title='Analyst Developer' company='Ace' />
                { ace(jobStyleTiny) }

                <Timeline dateStr='2003 - 2006' title='Sussex Unviversity' other='Education' />
                { sussexInfo(jobStyleTiny) }


            </Responsive> 

            <Responsive as={Grid} minWidth={1000}>
                <Grid.Row>
                    <Grid.Column width={4}>
                            <Timeline dateStr='Oct 2017 - Present' title='Senior Software Developer' company='Dwellant' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                            { dwellant(jobStyle) }
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={4}>
                            <Timeline dateStr='April 2015 - Oct 2017' title='Development Team Lead' company='Red River Software' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                            { redRiverTeamLead(jobStyle) }
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={4}>
                            <Timeline dateStr='Feb 2013 - April 2015' title='Senior Software Architect' company='Red River Software' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                            { redRiverArchitect(jobStyle) }
                    </Grid.Column>
                </Grid.Row>
                
                <Grid.Row>
                    <Grid.Column width={4}>
                            <Timeline dateStr='Feb 2011 - Feb 2013' title='Senior Developer' company='Red River Software' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                            { redRiverSeniorDeveloper(jobStyle) }
                    </Grid.Column>
                </Grid.Row>
                
                <Grid.Row>
                    <Grid.Column width={4}>
                            <Timeline dateStr='Nov 2009 - Feb 2011' title='Software Developer' company='Commerce Partnership' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                            { commercePartnership(jobStyle) }
                    </Grid.Column>
                </Grid.Row>
                
                <Grid.Row>
                    <Grid.Column width={4}>
                            <Timeline dateStr='2009 - Nov 2009' title='Analyst Developer' company='Accenture' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                            { accenture(jobStyle) }
                    </Grid.Column>
                </Grid.Row>

                
                <Grid.Row>
                    <Grid.Column width={4}>
                            <Timeline dateStr='2006 - 2009' title='Analyst Developer' company='Ace' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                            { ace(jobStyle) }
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={4}>
                            <Timeline dateStr='2003 - 2006' title='Sussex Unviversity' other='Education' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                            { sussexInfo(jobStyle) }
                    </Grid.Column>
                </Grid.Row>
            </Responsive>


        </Container>
    )
}

export default RegularPageHolder(cv);