import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Container, Responsive } from 'semantic-ui-react'

const wholeHeaderStyle = {
    padding: '64px 0 0',
    textTransform: 'uppercase',
}

const nameStyle = {
    fontSize: '41px',
    lineHeight: '41px',
    margin: '0 0 9px'
}

const subHeaderStyle = {
    fontSize: '12px',
    lineHeight: '26px',
    fontWeight: '600',
    color: '#fff',
    backgroundColor: '#c8bdae',
    padding: '0 45px',
    position: 'relative',
    // margin: '0 auto',
    verticalAlign: 'top',
    overflow: 'hidden',
}

const homePageHeader = () => {
    return(
        <Container>
            <Responsive as={Link} to="/" minWidth={1024}>
                <Header as='h1' style={wholeHeaderStyle}>
                    <Header.Content style={nameStyle}>
                        Jon Hawkins
                    </Header.Content>
                    <Responsive as={Header.Subheader} minWidth={1024} className="logoLine" style={subHeaderStyle}>
                        <span>
                            Father, Software developer and Mountain biker
                        </span>
                    </Responsive>
                </Header>
            </Responsive>            

            <Responsive as={Link} to="/" maxWidth={1023}>
                <Header as='h1' style={wholeHeaderStyle} textAlign='center'>
                    <Header.Content style={nameStyle}>
                        Jon Hawkins
                    </Header.Content>
                </Header>
            </Responsive>            
        </Container>
    )
}

export default homePageHeader;