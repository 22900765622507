import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Grid, Header, Responsive, Form, TextArea, Button, Input, Message } from 'semantic-ui-react'

import RegularPageHolder from './regularPageHolder';
import SubHeader from './headers/subHeader';

const renderForm = ({ name, email, subject, message, success }, handleChange, handleSubmit) => {
    return(
        <Container fluid>
            {success &&
                    <Message
                        success
                        header='Form Completed'
                        content="Thank you for your contact."
                    />
                }
            <Form 
                className='contactsForm'
                method="POST"
                name="contactForm"
                onSubmit={handleSubmit}
            >
                <Form.Field type="hidden" name="bot-field" />
                <Form.Field>
                    <Input placeholder='Your Name' icon='user' focus fluid required name='name' value={name} onChange={handleChange} />
                </Form.Field>

                <Form.Field>
                    <Input placeholder='Email' icon='mail' focus fluid required name='email' value={email} onChange={handleChange} />
                </Form.Field>

                <Form.Field>
                    <Input placeholder='Subject' focus fluid required name='subject' value={subject} onChange={handleChange} />
                </Form.Field>

                <Form.Field>
                    <TextArea placeholder='Message' rows={5} required name='message' value={message} onChange={handleChange} />
                </Form.Field>
                <Form.Field>
                    <Responsive as={Form.Field} maxWidth={767}>
                        <Button className='tinyButton'>Send Message</Button>
                    </Responsive>

                    <Responsive as={Container} minWidth={768} textAlign='right' fluid>
                        <Form.Field>
                            <Button>Send Message</Button>
                        </Form.Field>
                    </Responsive>
                </Form.Field>
            </Form>
        </Container>
        
    )
}

const renderParagraph = () => {
    return(
        <Container fluid style={{ margin: 0 }}>
            <Header as='h3'>
                Get in touch with me
            </Header>
            <p>Please do drop me a message if you would like to discuss anything further.</p>

            <Header as='h3'>
                You can find me on <a href="http://github.com/twistedtwig" target="_blank" style={{ color: 'inherit', marginRight: '10px' }} rel="noopener noreferrer">GitHub</a>
                <a href="http://github.com/twistedtwig" target="_blank" rel="noopener noreferrer">
                  <i aria-hidden="true" className="github big icon" style={{ color: '#c8bdae' }}></i>
                </a>
            </Header>
            <Header as='h3'>
                Additional Information
            </Header>
            <p>Horsham, West Sussex, UK</p>
        </Container>
    )
}

const initialState = { email: '', name: '', subject: '', message: '', botField: '', success: false };

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

class contact extends React.Component {
    state = initialState;

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    };
  
    handleSubmit = (e) => {
        e.preventDefault();

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contactForm", ...this.state })
          })
            .then((response) => {
                if(response.ok) {
                    this.setState(
                        {
                            ...initialState,
                            success: true
                        });
                } else {
                    alert('failed to send contract request');
                }
                                
            })
            .catch(error => alert(error));
    }

    render(){
        return(
            <Container>
                
                <Helmet>
                    <title>Contact Jon Hawkins - Houseofhawkins.com</title>
                    <meta name='description' content='contact jon jonathan hawkins hire me horsham west sussex github twistedtwig' />
                    <meta name='keywords' content='jon hawkins, jonathan hawkins, horsham, west sussex, twistedtwig' />
                </Helmet>

                <SubHeader title='Contact Me' />

                <Responsive as={Grid} minWidth={800}>

                    <Grid.Column width={10}>
                        { renderForm(this.state, this.handleChange, this.handleSubmit) }
                    </Grid.Column>

                    <Grid.Column width={6} style={{ marginTop: 20 }}>
                        { renderParagraph() }
                    </Grid.Column>
                </Responsive>

                <Responsive as={Container} maxWidth={799} className='smallGrid'>
                        { renderForm(this.state, this.handleChange, this.handleSubmit) }
                        <Container fluid style={{ margin: '50px 0 0 0'}}>
                            { renderParagraph() }
                        </Container>
                </Responsive>

            </Container>  
        )
    }

}
export default RegularPageHolder(contact);