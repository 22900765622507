import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Header, List, Button, Grid, Responsive } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

import Heading from './headers/homePageHeader';
import { links } from './pageLinks';

const svgStyle = {
    position: 'absolute',
    top: '50%',
    left: '0',
    height: '32px',
    width: '32px',
    transform: 'translate(0, -50%)',
    fill: '#c8bdae',
    transition: 'transform .35s',
}

const mainStyle = {
    padding: '122px 10px 98px',
    position: 'relative',
};

const getInTouchtyle = {
    background: '#c8bdae',
    border: '1px solid #c8bdae',
    margin: '0 0 68px',
    transition: 'background-color 0.25s ease, color 0.25s ease',
    borderRadius: '2px',
    color: '#fff',
    display: 'inlineBlock',
    verticalAlign: 'top',
    font: 'normal 400 18px/39px "Quattrocento Sans", sans-serif',
    textAlign: 'center',
    height: '38px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    outline: 'none',
    letterSpacing: '2px',
    width: '325px',
    padding: '0',
}

const textStyle = {
    fontFamily: '"Quattrocento Sans", sans-serif',
    fontSize: '14px',
    margin: '0 0 10px',
    textAlign: 'justify',
    maxWidth: 'inherit !important',
}

const paragraphStyle = {
    lineHeight: '26px',
    minHeight: '160px',
}

const titleStyle = {
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '2px',
    marginBottom: '15px',
    paddingBottom: '10px',
    borderBottom: '1px solid rgb(238, 236, 234)',
}

const navIconContainerStyle = {
    padding: '49px 0 1px',
    margin: '0 0 65px',
    borderBottom: '1px solid #eeecea',
    maxWidth: '413px',
}

const navItemStyle = {
    width: '50%',
    listStyle: 'none',
    margin: '0 0 42px',
    letterSpacing: '3px',
}

const navLinkStyle = {
    display: 'inline-block',
    lineHeight: '32px',
    height: '32px',
    fontSize: '14px',
    paddingLeft: '59px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#5c5244',
    textDecoration: 'none',
    position: 'relative',
    transition: 'color .35s',
}

const mapSvgs = (svgs) => {
    return svgs.map((svg, i) => {
        return(
            <List.Item style={navItemStyle} key={i} className='svgNavItem'>
                <Link to={svg.link} style={navLinkStyle}>
                    {svg.func(svgStyle)}
                    {svg.name}
                </Link>
            </List.Item>
        )
    })
}

const renderHeading = () => {
    return(
        <Container textAlign='left'>
            <Container className='homePageHeading'>
                <Heading />
            </Container>                
        </Container>
    );
}

const renderText = () => {
    return(
        <Container text style={textStyle} className='textParagraph'>
            <Header as="h2" style={titleStyle}>Welcome! Who am I?</Header>
            <p style={paragraphStyle}>
                Hi, I am passionate software developer who is always keen to produce clean, testable, scalable and reliable applications.
                The challenge of taking a idea and producing applications that truly meet and exceed the users expectations is so rewarding.
                I am also a father of three and a keen mountain biker and a bit of a geek. 
            </p>
        </Container>
    );
}

const home = () => {
    return(   
        <Grid>
            <Helmet>
                <title>Jon Hawkins - Houseofhawkins.com</title>
                <meta name='description' content='all about jon jonathan hawkins' />
            </Helmet>

            <Responsive as={Grid.Column} minWidth={1024} width={10} style={{ padding: '0 100px' }}>
                { renderHeading() }
                
                <Container style={mainStyle}>

                    { renderText() }

                    <Container style={navIconContainerStyle}>
                        <List horizontal>
                            { mapSvgs(links) }
                        </List>
                    </Container>

                    <Container textAlign='right'>
                        <Button as={Link} to="/contact" style={getInTouchtyle}>
                        Get In Touch
                        </Button>
                    </Container>

                </Container>
            </Responsive>

            <Responsive as={Grid.Column} maxWidth={1023} width={16}>
                <Heading />
                <Container style={{
                    marginTop: '35px'
                }}>
                    { renderText() }
                </Container>

                <Responsive as={Container} minWidth={500} style={navIconContainerStyle}>
                    <List horizontal>
                        { mapSvgs(links) }
                    </List>
                </Responsive>

                <Responsive as={Container} maxWidth={499} style={navIconContainerStyle}>
                    <List style={{minHeight:450, marginTop: 20 }}>
                        { mapSvgs(links) }
                    </List>
                </Responsive>                
            </Responsive>
        </Grid>
    )
}

export default home;