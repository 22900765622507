import React from 'react';
import {
    Switch,
    Route,
    withRouter,
  } from "react-router-dom";

  
import Home from './home';
import About from './about';
import Cv from './cirriculum/cv';
import Skills from './skills/technicalSkills';
import Contact from './contact';

import Testing from './test'

const AboutComponent = withRouter(props => <About {...props}/>);
const CvComponent = withRouter(props => <Cv {...props}/>);
const SkillsComponent = withRouter(props => <Skills {...props}/>);
const ContactComponent = withRouter(props => <Contact {...props}/>);

const routing = () => {
    return(
        <Switch>
            <Route path="/about">
                <AboutComponent />
            </Route>
            <Route path="/cv">
                <CvComponent />
            </Route>
            <Route path="/technicalSkills">
                <SkillsComponent />
            </Route>
            <Route path="/contact">
                <ContactComponent />
            </Route>
            
            
            <Route path="/testing">
                <Testing />
            </Route>
            
            
            <Route path="/">
                <Home />
            </Route>
            
        </Switch>
    )
}

export default routing;