import React from 'react';
import { Container, Responsive } from 'semantic-ui-react'

const containerStyle = {
    width: '282px',
    display: 'table-cell',
    verticalAlign: 'top',
    padding: '45px 0 37px 0',
}

const dateStyle = {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '34px',
    margin: '0 0 4px',
    textTransform: 'uppercase',
    position: 'relative',
    right: '-15px',
}


const dateStyleTiny = {
    fontSize: '15px',
    lineHeight: '34px',
    float: 'right',
}

const titleStyle = {
    fontstyle: 'italic',
    fontSize: '12px',
    lineHeight: '26px',
}

const titleStyleTiny = {
    fontSize: '12px',

}

const baseCompanyAndOtherStyle = {
    lineHeight: '19px',
    color: '#fff',
    fontWeight: 'bold',
    letterSpacing: '2px',
    fontSize: '10px',
    textTransform: 'uppercase',
    background: '#c8bdae',
    padding: '0 5px',
}

const companyStyleTiny = {
    ...baseCompanyAndOtherStyle,
    lineHeight: '34px',
    fontSize: '15px',
}

const otherStyleTiny = {
    ...baseCompanyAndOtherStyle,
    background: '#3e3e3e',
    lineHeight: '34px',
    fontSize: '15px',
}

const companyStyle = {
    ...baseCompanyAndOtherStyle,
    height: '17px',
    position: 'relative',
}

const otherStyle = {
    ...companyStyle,
    background: '#3e3e3e',
}


const timeline = ({ dateStr, title, company, other }) => {
    return(
        <Container fluid>
            <Responsive as={Container} minWidth={1000} textAlign='right' className='jobTimeLine' style={containerStyle}>
                <div className='date' style={dateStyle}>{dateStr}</div>
                <div className='title' style={titleStyle}>{title}</div>
                { company && <div className='company' style={companyStyle}>{company}</div> }
                { other && <div className='other' style={otherStyle}>{other}</div> }
            </Responsive>

            <Responsive as={Container} maxWidth={999} minWidth={800} className='jobTimeLineTiny'>
                { company && <div className='company' style={companyStyleTiny}>
                    <span>{company}: </span>
                    <span className='date' style={dateStyleTiny}>{dateStr}</span>
                    <span className='title' style={titleStyleTiny}>{title}</span>
                </div> }
                { other && <div className='other' style={otherStyleTiny}>
                    <span>{other}: </span>
                    <span className='date' style={dateStyleTiny}>{dateStr}</span>
                    <span className='title' style={titleStyleTiny}>{title}</span>
                </div> }    
            </Responsive>

            <Responsive as={Container} maxWidth={799} minWidth={600} className='jobTimeLineTiny' style={{ marginBottom: '20 !important' }}>
                { company && 
                    <div className='company'>
                        <div style={companyStyleTiny}>
                            {company}
                            <span className='date' style={dateStyleTiny}>{dateStr}</span>
                        </div>                        
                        <div className='title' style={{ fontWeight: 'bold', marginTop : 10 }}>{title}</div>
                    </div> 
                }
                { other && <div className='other' style={otherStyleTiny}>
                    <span>{other}</span>
                    <span className='date' style={dateStyleTiny}>{dateStr}</span>
                    <span className='title' style={titleStyleTiny}>{title}</span>
                </div> }    
            </Responsive>

            <Responsive as={Container} maxWidth={599} minWidth={400} className='jobTimeLineTiny' style={{ marginBottom: '20 !important' }}>
                { company && 
                    <div className='company'>
                        <div style={companyStyleTiny}>{company}</div>                        
                        <div className='date' style={dateStyleTiny}>{dateStr}</div>
                        <div className='title' style={{ fontWeight: 'bold', marginTop : 10 }}>{title}</div>
                    </div> 
                }
                { other && 
                    <div className='other'>
                        <div style={otherStyleTiny}>{other}</div>
                        <div className='date' style={dateStyleTiny}>{dateStr}</div>
                        <div className='title' style={{ fontWeight: 'bold', marginTop : 10 }}>{title}</div>
                    </div>
                }    
            </Responsive>

            <Responsive as={Container} maxWidth={399} className='jobTimeLineTiny' style={{ marginBottom: '20 !important' }}>
                { company && 
                    <div className='company'>
                        <div style={companyStyleTiny}>{company}</div>                        
                        <div className='title' style={{ fontWeight: 'bold', marginTop : 10 }}>{title}</div>
                        <div className='date'>{dateStr}</div>
                    </div> 
                }
                { other && 
                    <div className='other'>
                        <div style={otherStyleTiny}>{other}</div>
                        <div className='title' style={{ fontWeight: 'bold', marginTop : 10 }}>{title}</div>
                        <div className='date'>{dateStr}</div>
                    </div>
                }    
            </Responsive>
        </Container>
        
    )
}

export default timeline;