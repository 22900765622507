import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Dropdown, Responsive } from 'semantic-ui-react'
import { allLinks } from '../pageLinks';

const barsMenuStyle = {
    width: '75px',
    backgroundColor: '#c8bdae',
    border: '1px solid #f1efeb',
}

const renderMenu = (props) => {
    return(
        <Dropdown button icon='bars' className='massive barsMenu' style={barsMenuStyle}>
            <Dropdown.Menu className='mobileMenu'>
                {allLinks.map((link, i) => {
                    return <Dropdown.Item key={i} as={Link} text={link.name} to={link.link} className={props.location.pathname === link.link ? 'currentNavPage' : null} />
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default class mobileHeader extends React.Component {
    render(){
        return(
            <header>
                <Responsive as={Container} minWidth={475} textAlign='right' fluid>                    
                    {renderMenu(this.props)}
                </Responsive>

                <Responsive as={Container} maxWidth={474} textAlign='right' className='barsMenuSmall' fluid>                    
                    {renderMenu(this.props)}
                </Responsive>
            </header>
        )
    }
}