import React from 'react';

import Heading from './headers/regularPageHeader';
import { Responsive, Container } from 'semantic-ui-react';

const RegularPageHolder = (PageComponent) => {
    return class extends React.Component {

        render() {
            return (
                <Container className='regularPageContainer'>
                    <Heading {...this.props} />
                    <Responsive as={Container} minWidth={1024} style={{ padding: '0 100px 150px 100px', maxWidth: '90% !important' }} fluid>
                        <PageComponent {...this.props} />
                    </Responsive>
                    <Responsive as={Container} maxWidth={1023} minWidth={800} style={{ paddingBottom: '150px' }} className='mediumPageContainer' fluid>
                        <PageComponent {...this.props} />
                    </Responsive>
                    <Responsive as={Container} maxWidth={799} minWidth={500} style={{ paddingBottom: '150px' }} className='smallPageContainer' fluid>
                        <PageComponent {...this.props} />
                    </Responsive>
                    <Responsive as={Container} maxWidth={499} style={{ paddingBottom: '150px' }} className='tinyPageContainer' fluid>
                        <PageComponent {...this.props} />
                    </Responsive>
                </Container >
            )
        }
    }
}

export default RegularPageHolder;
