import React from 'react';

class scriptHandler extends React.Component {
    constructor(props) {
       super(props);
       this.handleLoad = this.handleLoad.bind(this);
    }

    render(){
        return(<span></span>)
    }
   
    componentDidMount() {
       window.addEventListener('load', this.handleLoad);
    }
   
    componentWillUnmount() { 
      window.removeEventListener('load', this.handleLoad)  
    }
   
    handleLoad() {

    }
   }

   export default scriptHandler;
