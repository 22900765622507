import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Grid, Header, Responsive } from 'semantic-ui-react'

import RegularPageHolder from './regularPageHolder';
import SubHeader from './headers/subHeader';
import Gallery from './ImageGallery/imageGallery';

const renderText = () => {
    return(
        <Grid.Column>
            <Header as='h3'>Work Life</Header>
            <p>
                My week days are spent at Dwellant as a Senior Developer, working remotely most of the time.
                Dwellant is a state-of-the-art tool specially crafted to ease communication between managing agents and residents, simplify task management and solve problems that occur when managing a building.
                The company follows an Agile methodology and the Scrum process. The agile philosophy fits well with us, it facilities our natural ability and desire to respond to our customers needs quickly and efficiently. 
                Our primary language is C# and .Net 4.8 and Core 3.1. Client side languages we have more a traditional ASPNET MVC application as well as multiple ReactJS sites in development.
            </p>

            <Header as='h3'>Home Life</Header>
            <p>
                After my long commute from the office; across the landing and walking the down long flight of stairs; I get to spend some time with the family. Charlotte, Dale, Ethan, Kendra and the dog Raven.                        
            </p>

            <Header as='h3'>Hobbies</Header>
            <p>
                I really do enjoy coding and often have a personal project or two on the go, experimenting with different technologies or trying to create something interesting (to me at least).  
            </p>
            <p>
                Exercise is important to me.  Being so still during my working day, getting up and moving around helps get the blood going. 
                I love to ride my bike.  I am a mountain biker through and through, but do enjoy a good road ride with friends.  
                Weight lifting has always been something I take pleasure in, its a great feeling and helps burn off some of the calories; as well as helping trying to keep my body strong to avoid injuries.
            </p>
            <p>To relax I have recently started to bake, and found that I get a real sense of achievement from it.  Of an evening you could well find me in front of the TV watching a good TV series</p>
        </Grid.Column>
    )
}

const about = () => {
    return(    
        <Container>
            
        <Helmet>
            <title>About Jon Hawkins - Houseofhawkins.com</title>
            <meta name='description' content='about jon jonathan hawkins mountain bike exercise coding' />
        </Helmet>

            <SubHeader title='About Me' />

            <Responsive as={Grid} minWidth={800}>
            <Grid columns={2}>
                { renderText() }

                <Grid.Column>
                    <Gallery numberOfTiles={4} />
                </Grid.Column>
            </Grid>
            </Responsive>

            <Responsive as={Grid} maxWidth={799} className='smallGrid'>
                <Grid columns={1}>
                    { renderText() }
                </Grid>
            </Responsive>

        </Container>

    )
}

export default RegularPageHolder(about);
