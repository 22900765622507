import React from 'react';
import { Container, Header, Responsive } from 'semantic-ui-react'

const subHeader = ({ title }) => {
return(
    <Container className='ribbon aboutme-ribbon' style={{ marginBottom: 50, marginTop: 40 }}>
        <Responsive as={Container} minWidth={600} fluid>
            <Header as='h3' size='medium' textAlign='center'>{ title }</Header>
        </Responsive>
        <Responsive as={Container} maxWidth={599} fluid className='tinyHeading'>
            <Header as='h3' size='medium' textAlign='center' style={{ fontSize: '1em' }}>{ title }</Header>
        </Responsive>
        <Responsive as='span' minWidth={800}></Responsive>
    </Container>
)
}

export default subHeader;