import React from 'react';
import { Container, Image } from 'semantic-ui-react'

import { imageList, getImageUrl  } from './imageFunctions';
import ImageModal from './imageModal';

const getRandomCollectionOfImages = numberOfImages => {
    let imageCollection = imageList.slice();
    let newCollection = [];

    while(newCollection.length < numberOfImages) {
        if(newCollection.length >= imageList.length){
            break;
        }

        const num = Math.floor(Math.random() * imageCollection.length);

        const image = imageCollection.splice(num-1, 1)[0];
        newCollection.push(image);
    }
    return newCollection;    
}

class imageGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            numberOfTiles: props.numberOfTiles, 
            images: getRandomCollectionOfImages(props.numberOfTiles),   
            toggle: false, 
            modalOpen: false,
        };
      }

      componentDidMount(){
        this.timerID = setInterval(
            () => {
                this.setState({ images: getRandomCollectionOfImages(this.state.numberOfTiles), toggle: !this.state.toggle });
            },
            10000
          );        
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }

    handleToggleModal = (open, image) => {
        this.setState({ modalOpen: open, selectedImage: image });
    }

    render(){
        const { images, toggle, modalOpen, selectedImage } = this.state;
        return(
            <Container className='imageGallery' textAlign='center'>
                <Image.Group size='medium' className={ toggle ? 'toggle1' : 'toggle2'}>
                { images.map((item, i) =>{
                        const url = getImageUrl(item);
                        return(
                            <Image 
                                key={item.fileName}
                                src={url}
                                id={item.fileName}
                                size='small'
                                bordered 
                                alt={item.title}
                                rounded
                                centered
                                className='image' 
                                onClick={() => this.handleToggleModal(true, item)}
                            />
                        )
                    })}
                </Image.Group>
                
                {/* 

                    todo:
                    add click to image to open modal that will show them all, with next and previous buttons
                    have a gallery button on modal that will show all images as thumbnails
                    test it at 1024, 800, ensure not visible below 800
                */}

                <ImageModal 
                    open={modalOpen}   
                    onClose={() =>this.handleToggleModal(false, null)}
                    selectedImage={selectedImage} 
                />                
            </Container>
        )
    }
}

export default imageGallery;