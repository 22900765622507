import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  withRouter
} from "react-router-dom";

import { Container } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import Routing from './routing';
import BackgroundMe from './backgroundMeImage';
import ScriptHandler from './index.jsx';
import HiddenImageGallery  from './imagePreLoader';

const BackgroundMeComponent = withRouter(props => <BackgroundMe {...props}/>);

const wholePageStyle = {
  position: 'relative',
  minHeight: '100%',
  height: 'auto',
  display: 'block'
}

const wholeContentStyle = {
  top: 0,
  bottom: '98px',
  left: 0,
  overflow: 'hidden',
  zIndex: 0,
  width: '100%',
}

function App() {
  return (  
      <Router style={wholeContentStyle}>
        <BackgroundMeComponent />

        <Container style={wholePageStyle} fluid>
          <Container style={wholeContentStyle} fluid>
            <Routing />
          </Container>
        </Container>

        <ScriptHandler />
        <HiddenImageGallery />

    </Router>
  );
}

export default App;
