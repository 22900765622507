import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, List, Responsive } from 'semantic-ui-react'
import { allLinks } from '../pageLinks';

import MobileHeader from './mobileHeader';

const intialState = allLinks.map((item, i) => {
    return {
        ...item,
        visible: false,
    }
});

const svgStyle = {
    height: '32px',
    width: '32px',
    fill: '#c8bdae',
    transition: 'transform .35s',
    transform: 'translate(0, 25%)',
    marginRight: '10px',
}

const linkStyle = {
    letterSpacing: '2px',
    color: '#5c5244',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    padding: '0px 5px 37px 5px',
    transition: 'color .35s',
}

const spacedLinkStyle = {
    ...linkStyle,
    padding: '0px 25px 37px 35px',
}

const desktopHeaderStyle = {
    marginBottom: '25px',
    paddingBottom: '30px',
    borderBottom
        : '7px solid #eeecea',
}

const setLinkHover = (setIsShown, item, visible) => {
    const values = intialState.map((x, i) => {
        return {
            ...x,
            visible: item.link === x.link ? visible : false,
        }
    })

    setIsShown(values);
}

const isLinkHover = (isShown, currentItem) => {
    var item = isShown.filter((x) => { return x.link === currentItem.link; })[0];
    return item.visible;
}

const isAnyHover = (isShown) => {
    var list = isShown.filter((x) => { return x.visible === true; });
    return list.length > 0;
}

const renderListItem = (props, setIsShown, isShown, item, withIcon) => {

    const currentNavPage = 'currentNavPage';
    const isHovering = isLinkHover(isShown, item);
    const anyIsHovering = isAnyHover(isShown);
    const currentPageNav = props.location.pathname === item.link ? currentNavPage : '';

    const cssNames = anyIsHovering ? isHovering ? currentNavPage : '' : currentPageNav;

    return (
        <List.Item key={item.key}>
            <Responsive as={Link} to={item.link} maxWidth={1299} style={linkStyle} className={cssNames}
                onMouseEnter={() => setLinkHover(setIsShown, item, true)}
                onMouseLeave={() => setLinkHover(setIsShown, item, false)}>
                {withIcon ? item.func(svgStyle) : null}
                {item.name}
            </Responsive>
            <Responsive as={Link} to={item.link} minWidth={1300} style={spacedLinkStyle} className={cssNames}
                onMouseEnter={() => setLinkHover(setIsShown, item, true)}
                onMouseLeave={() => setLinkHover(setIsShown, item, false)}>
                {withIcon ? item.func(svgStyle) : null}
                {item.name}
            </Responsive>
        </List.Item>
    )
}

const renderDesktopHeader = (props, setIsShown, isShown, withIcon) => {
    return (
        <header style={desktopHeaderStyle}>
            <List horizontal>
                {allLinks.map((link, i) => {
                    return renderListItem(props, setIsShown, isShown, { key: i, ...link }, withIcon)
                })}
            </List>
        </header>
    )
}

function Heading(props) {
    const [isShown, setIsShown] = useState(intialState);

    return (
        <Container fluid style={{ marginTop: '20px' }} className='menuHeader' textAlign='center'>
            <Responsive as={Container} minWidth={1024} fluid style={{ padding: '0 100px' }} >
                {renderDesktopHeader(props, setIsShown, isShown, true)}
            </Responsive>
            <Responsive as={Container} minWidth={800} maxWidth={1023} fluid style={{ padding: '0 100px' }} >
                {renderDesktopHeader(props, setIsShown, isShown, false)}
            </Responsive>
            <Responsive as={Container} maxWidth={799} minWidth={100} fluid>
                <MobileHeader {...props } />
            </Responsive>
        </Container>
    )
}

export default Heading;