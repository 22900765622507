import React from 'react';
import lazyComponent from '../lazyComponent';

import { Container, Header } from 'semantic-ui-react'

import { easeQuadInOut } from "d3-ease";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import AnimatedProgressProvider from "../progressHelpers/AnimatedProgressProvider";
import "react-circular-progressbar/dist/styles.css";


const pieStyle = {
    width: '75%',
    marginBottom: '15px',
    textAlign: 'center',
}

const skill = ({ skill }) => {
    return (
        <Container>
            <Container fluid style={pieStyle}>
                <AnimatedProgressProvider
                    valueStart={0}
                    valueEnd={skill.percentage}
                    duration={1.4}
                    easingFunction={easeQuadInOut}
                >
                    {value => {
                        const roundedValue = Math.round(value);
                        return (
                            <CircularProgressbar
                                value={value}
                                strokeWidth={2}
                                text={`${roundedValue}%`}
                                /* This is important to include, because if you're fully managing the
                            animation yourself, you'll want to disable the CSS animation. */
                                styles={buildStyles(
                                    {
                                        pathTransition: "none",
                                        backgroundColor: "#3e98c7",
                                        textColor: 'rgb(92, 82, 68)',
                                        pathColor: "#c8bdae",
                                        trailColor: "transparent",
                                    }
                                )}
                            />
                        );
                    }}
                </AnimatedProgressProvider>
            </Container>
            <Header as="h3" textAlign='center' style={{ marginTop: 20, height: 75 }}>
                <Header.Content>
                    {skill.title}
                    <Header.Subheader>{skill.level}, {skill.years} years</Header.Subheader>
                </Header.Content>
            </Header>
            <p style={{ marginTop: 10 }}>{skill.description}</p>
        </Container>
    )
}

export default lazyComponent()(skill);
