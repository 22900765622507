import React from 'react';

import { imageList, getImageUrl } from './ImageGallery/imageFunctions';

class hiddenImageGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadLimages: false,
        };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            2000
        );
    }

    tick() {
        this.setState({ loadLimages: true });
        clearInterval(this.timerID);
    }

    render() {
        if (!this.state.loadLimages) {
            return (<div></div>)
        }

        return (
            <div>
                {imageList.map((image, i) => <img key={i} style={{ width: 1, display: 'none' }} src={getImageUrl(image)} alt={image.title} />)}
            </div>
        )
    }
}

export default hiddenImageGallery;